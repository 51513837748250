// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-blog-blog-template-js": () => import("./../../../src/components/blog/blog-template.js" /* webpackChunkName: "component---src-components-blog-blog-template-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-austen-table-js": () => import("./../../../src/pages/AustenTable.js" /* webpackChunkName: "component---src-pages-austen-table-js" */),
  "component---src-pages-bedroom-js": () => import("./../../../src/pages/bedroom.js" /* webpackChunkName: "component---src-pages-bedroom-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-badru-jehan-kirmani-js": () => import("./../../../src/pages/contact/Badru_Jehan_Kirmani.js" /* webpackChunkName: "component---src-pages-contact-badru-jehan-kirmani-js" */),
  "component---src-pages-contact-huma-js": () => import("./../../../src/pages/contact/huma.js" /* webpackChunkName: "component---src-pages-contact-huma-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contact-nathiq-js": () => import("./../../../src/pages/contact/nathiq.js" /* webpackChunkName: "component---src-pages-contact-nathiq-js" */),
  "component---src-pages-contact-ramesh-js": () => import("./../../../src/pages/contact/ramesh.js" /* webpackChunkName: "component---src-pages-contact-ramesh-js" */),
  "component---src-pages-contact-sathya-js": () => import("./../../../src/pages/contact/sathya.js" /* webpackChunkName: "component---src-pages-contact-sathya-js" */),
  "component---src-pages-contact-shafi-js": () => import("./../../../src/pages/contact/shafi.js" /* webpackChunkName: "component---src-pages-contact-shafi-js" */),
  "component---src-pages-contact-shuaib-js": () => import("./../../../src/pages/contact/shuaib.js" /* webpackChunkName: "component---src-pages-contact-shuaib-js" */),
  "component---src-pages-contact-syed-js": () => import("./../../../src/pages/contact/syed.js" /* webpackChunkName: "component---src-pages-contact-syed-js" */),
  "component---src-pages-crockery-js": () => import("./../../../src/pages/crockery.js" /* webpackChunkName: "component---src-pages-crockery-js" */),
  "component---src-pages-dining-js": () => import("./../../../src/pages/dining.js" /* webpackChunkName: "component---src-pages-dining-js" */),
  "component---src-pages-foyer-area-js": () => import("./../../../src/pages/foyer_area.js" /* webpackChunkName: "component---src-pages-foyer-area-js" */),
  "component---src-pages-height-adjustable-desk-js": () => import("./../../../src/pages/Height_adjustable_desk.js" /* webpackChunkName: "component---src-pages-height-adjustable-desk-js" */),
  "component---src-pages-house-js": () => import("./../../../src/pages/house.js" /* webpackChunkName: "component---src-pages-house-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kitchen-js": () => import("./../../../src/pages/kitchen.js" /* webpackChunkName: "component---src-pages-kitchen-js" */),
  "component---src-pages-living-area-js": () => import("./../../../src/pages/living_area.js" /* webpackChunkName: "component---src-pages-living-area-js" */),
  "component---src-pages-office-js": () => import("./../../../src/pages/office.js" /* webpackChunkName: "component---src-pages-office-js" */),
  "component---src-pages-product-js": () => import("./../../../src/pages/product.js" /* webpackChunkName: "component---src-pages-product-js" */),
  "component---src-pages-shoerack-js": () => import("./../../../src/pages/Shoerack.js" /* webpackChunkName: "component---src-pages-shoerack-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-pages-tvunit-js": () => import("./../../../src/pages/tvunit.js" /* webpackChunkName: "component---src-pages-tvunit-js" */),
  "component---src-pages-wardrobe-js": () => import("./../../../src/pages/wardrobe.js" /* webpackChunkName: "component---src-pages-wardrobe-js" */)
}

